import dijit_popup = require("dijit/popup");
import dojo_on = require("dojo/on");

import { Is } from "core";
import * as Dom from "Everlaw/Dom";
import * as Geom from "Everlaw/Geom";
import * as Input from "Everlaw/Input";
import * as UI from "Everlaw/UI";
import * as Util from "Everlaw/Util";
import { EVERCLASS, everClassProp } from "Everlaw/EverAttribute/EverClass";

export enum Rotation {
    NONE,
    CLOCKWISE_90,
    CLOCKWISE_180,
    CLOCKWISE_270,
}

export enum DisplayMode {
    // Pages match the intrinsic size of the content, and are horizontally centered.
    MAX_CONTENT,
    // Pages shrink to the intrinsic size of the content or the viewport (whichever is smaller).
    FIT_CONTENT,
    // Pages are a fixed width, or shrink to the width of the viewport (whichever is smaller).
    EXPAND_TO_WIDTH,
}

/**
 * An item displayed in a ContinuousScroll widget.
 */
export abstract class BaseScrollItem {
    /**
     * The outermost node for the item, which includes the visible page and its margins.
     */
    readonly node: HTMLElement;
    /**
     * The visible page.
     */
    readonly page: HTMLElement;

    protected constructor(
        protected readonly content: HTMLElement,
        public pageNum: number,
        padding: number,
    ) {
        this.node = Dom.li(
            {
                class: "continuous-scroll-item",
                style: { padding: `${padding}px ${padding}px` },
            },
            (this.page = Dom.div({ class: "continuous-scroll-item__page" }, content)),
        );
    }

    /**
     * Initialize a new or previously-destroyed scroll page.
     */
    onInitDom(): void {
        // By default, do nothing.
    }

    /**
     * Returns the distance from the current page that our caching should apply to.
     */
    cacheWidth(): number {
        return ContinuousScroll.CACHE_WIDTH;
    }

    /**
     * This may be called when {@link ContinuousScroll.pages} fills up.
     */
    destroy(): void {
        // By default, do nothing.
    }
}

/**
 * An item whose size will not automatically adjust to the width of the viewport.
 * {@link ContinuousScroll} will render any updates to size, scale, or rotation.
 */
export abstract class FixedSizeScrollItem extends BaseScrollItem {
    /**
     * This node may be scaled and rotated.
     */
    protected readonly inner: HTMLElement;

    /**
     * The scale at which the item will be rendered at.
     */
    scale: number = 1;

    /**
     * @param content - The actual page content.
     * @param pageNum - The index of the scroll item, starting from 0.
     * @param height - The desired content height, not accounting for rotation.
     * @param width - The desired content width, not accounting for rotation.
     * @param padding - The desired padding between scroll items.
     */
    protected constructor(
        content: HTMLElement,
        pageNum: number,
        public height: number,
        public width: number,
        padding: number = ContinuousScroll.DEFAULT_PADDING,
    ) {
        const inner: HTMLElement = Dom.div({ class: "fixed-size-scroll-item" }, content);
        super(inner, pageNum, padding);
        this.inner = inner;

        // The image is always full width inside its container - we set the container width/height
        // when scaling.
        Dom.style(content, {
            width: "100%",
            height: "100%",
        });
    }

    rescale(rotation: Rotation): void {
        const isRotated: boolean =
            rotation === Rotation.CLOCKWISE_90 || rotation === Rotation.CLOCKWISE_270;
        // noinspection JSSuspiciousNameCombination
        const rotated: Size = isRotated
            ? { height: this.width, width: this.height }
            : { height: this.height, width: this.width };

        // Container node size accounts for rotation, zoom, and padding.
        Dom.style(this.page, {
            height: `${rotated.height * this.scale}px`,
            width: `${rotated.width * this.scale}px`,
        });
        // Inner node size accounts for zoom, and is transformed and offset for the rotation.
        const offset: number = isRotated ? (this.scale * (this.width - this.height)) / 2 : 0;
        Dom.style(this.inner, {
            height: `${this.height * this.scale}px`,
            width: `${this.width * this.scale}px`,
            top: `${offset}px`,
            left: `${-offset}px`,
            transform: `rotate(${rotation * 90}deg)`,
        });
    }
}

/**
 * Placeholder item when an item hasn't been fetched for a page.
 */
export class TombstoneItem extends FixedSizeScrollItem {
    constructor(pageNum: number, height: number, width: number, padding: number) {
        super(
            Dom.div(
                {
                    class: "continuous-scroll-tombstone",
                    ...everClassProp(EVERCLASS.SCROLL_VIEWER.TOMBSTONE),
                },
                Dom.div({ class: "continuous-scroll-tombstone__anim" }),
                Dom.div({ class: "continuous-scroll-tombstone__inner" }),
            ),
            pageNum,
            height,
            width,
            padding,
        );
    }
}

/**
 * Error item when an item is failed to be fetched.
 */
export class ErrorItem extends FixedSizeScrollItem {
    constructor(pageNum: number, height: number, width: number, url: string, padding: number) {
        super(
            Dom.img({
                class: "continuous-scroll-error",
                src: url,
                alt: "Sorry, this image failed to render.",
            }),
            pageNum,
            height,
            width,
            padding,
        );
    }
}

export const enum ZoomDim {
    WIDTH,
    HEIGHT,
}

export interface ZoomInfo {
    dim: ZoomDim;
    scale: number;
}

interface Anchor {
    pageNum: number;
    offset: number;
}

/**
 * A generic interface for any object that has a width and height.
 */
export interface Size {
    width: number;
    height: number;
}

function determineMaxDivHeight(): number {
    // Some current numbers:
    // Chrome 74: 33.5M
    // FF 66: 9M
    // Edge: 1.5M
    // IE11: 1.5M

    // Search 1M to 100M to within 100K.
    // We assume the lower bound works.
    let low = 1000000;
    let high = 100000000;
    const div = Dom.create("div", { visibility: "hidden" }, document.body);
    while (high - low > 100000) {
        const mid = (high + low) / 2;
        Dom.style(div, "height", `${mid}px`);
        // We need to be a bit fuzzy because of possible rounding/pixel precision issues.
        if (Math.abs(div.scrollHeight - mid) < 2) {
            // This height worked - increase our lower bound.
            low = mid;
        } else {
            // This height failed - decrease our upper bound.
            high = mid;
        }
    }
    Dom.destroy(div);
    // We know the lower bound worked, so return that (with some allowance for our rounding).
    return low - 2;
}

/**
 * Encapsulates the scroller runway, and the items on it. Handles DOM operations, but doesn't
 * manage size or position.
 *
 * <pre>
 *   ┌────────────┐
 *   │            │
 *   │            │   Padding to set the vertical item positions
 *   │            │
 *   ├────────────┤
 *   │┼┼┼┼┼┼┼┼┼┼┼┼│
 *   ├────────────┤   Pre-roll items
 *   │┼┼┼┼┼┼┼┼┼┼┼┼│
 * ┌─┼────────────┼┬┐
 * │ │┼┼┼┼┼┼┼┼┼┼┼┼│││
 * │ ├────────────┤││ Viewport
 * │ │┼┼┼┼┼┼┼┼┼┼┼┼│││
 * └─┼────────────┼┴┘
 *   │┼┼┼┼┼┼┼┼┼┼┼┼│
 *   ├────────────┤   Post-roll items
 *   │┼┼┼┼┼┼┼┼┼┼┼┼│
 *   ├────────────┤
 *   │            │
 *   └────────────┘   Height set to the sum of estimated item heights
 * </pre>
 */
class RenderedItems<I extends BaseScrollItem> {
    readonly node: HTMLElement;
    /**
     * Container for rendered {@link items}.
     */
    private readonly runway: HTMLElement;

    /**
     * A list of our currently rendered (in-DOM) items. These may be pages, tombstone (loading)
     * pages, or error pages.
     */
    private items: I[] = [];

    constructor() {
        this.node = Dom.div(
            { class: "continuous-scroll" },
            (this.runway = Dom.ol({ class: "continuous-scroll__items" })),
        );
    }

    length(): number {
        return this.items.length;
    }

    forEach(callback: (element: I, index: number, array: I[]) => void): void {
        this.items.forEach(callback);
    }

    first(): I | undefined {
        return this.items[0];
    }

    last(): I | undefined {
        return this.items[this.items.length - 1];
    }

    addFirst(item: I): void {
        Dom.place(item, this.runway, 0);
        this.items.unshift(item);
    }

    addLast(item: I): void {
        Dom.place(item, this.runway);
        this.items.push(item);
    }

    /**
     * Replace the item at a given index, and return the item that was removed.
     */
    replaceItem(index: number, item: I): I | undefined {
        if (!(0 <= index && index < this.items.length)) {
            return undefined;
        }

        const removed: I = this.items[index];
        Dom.place(item, removed, "replace");
        this.items[index] = item;

        return removed;
    }

    removeFirst(): I | undefined {
        const removed: I | undefined = this.items.shift();
        if (removed) {
            Dom.remove(removed.node);
        }
        return removed;
    }

    removeLast(): I | undefined {
        const removed: I | undefined = this.items.pop();
        if (removed) {
            Dom.remove(removed.node);
        }
        return removed;
    }

    /**
     * The empty space that represents the estimated height of all items prior to our render frame.
     * This must be called whenever the first item in our render window changes.
     */
    setOffset(offset: number): void {
        Dom.style(this.runway, { paddingTop: `${offset}px` });
    }

    /**
     * Update the native scrollbar by setting the runway height.
     */
    setHeight(height: number): void {
        Dom.style(this.runway, { height: `${height}px` });
    }

    /**
     * We apply this class so that elements with some rotation-specific style (in particular, the
     * highlight corner resize cursors...) can get updated correctly.
     */
    toggleLandscapeStyle(enabled: boolean): void {
        Dom.toggleClass(this.node, "landscape-mode", enabled);
    }
}

interface ContinuousScrollParams<I extends BaseScrollItem> {
    parent: HTMLElement;
    // The current zoom preference, which is used to draw the initial loading page.
    initZoom?: ZoomInfo;
    // Additional padding to add to the bottom of the last item.
    gutterHeight: number;
    // Get the current desired number of the pre roll and post roll pages. Pre roll prefers the
    // pages added to the DOM before the current page. Post roll refers to the pages after. Called
    // whenever the scaling or rotation of the viewer changes.
    getPrePostRoll: () => [number, number];
    // Get the current pages. curPage is the current page number. visible is the set of pages
    // currently visible in the viewer. nonVisible is the set of pages that are within the pre and
    // post roll range but aren't visible in the viewer. As the items are loaded, onLoad should be
    // called for each item.
    loadPages: (curPage: number, visible: number[], nonVisible: number[]) => void;
    // Called when the range of items in the viewer changes or something causes the range to be
    // recalculated.
    onUpdate?: (start: number, end: number) => void;
    // Called when pages are inserted into the DOM. If the item isn't loaded, then either a
    // tombstone or an error item is passed.
    onInsert?: (item: I | ErrorItem | TombstoneItem) => void;
    // Allows an implementer to override the calculation of what's considered the current page.
    currPageOverride?: () => number | void;
    // Called whenever a scroll has "finished".
    onScroll?: (pageNum: number) => void;
    // If provided, this will be called during the call to ContinuousScroll.init, right before any
    // pages beyond the first are loaded.
    onInit?: () => void;
    isFullScreenMode?: boolean;
    // Should clicking the ContinuousScroll close the top Popup element?
    closePopupOnTap?: boolean;
    // Should popups around items that are outside of the window be left open?
    keepOutOfWindowPopup?: boolean;
    // How should pages expand relative to the viewport? Defaults to fixed size pages.
    displayMode?: DisplayMode;
    padding?: number;
}

/**
 * A continuously scrolling, dynamically loading, paginated content viewer for a collection of items
 * (the cardinality of which we know ahead of time).
 * The pages don't have to be the same size, nor do we have to know the sizes ahead of time.
 * A small window of items around the current page is actually displayed in the DOM; we may have
 * some additional nearby items cached and ready to be displayed; if we scroll/jump past these we
 * display placeholders ("tombstones") and fetch/drop in the items when we get them.
 *
 * The display also supports rotating (by multiples of 90 degrees) and scaling the items (to current
 * page width/height or in/out relative to the current scale).
 *
 * About scaling images: whenever we load an image we need to know what scale it's been rendered at
 * (this scale does not need to be consistent between pages). e.g. page 2 of a PDF might
 * be 1000x1000 naturally but we render it at 500x500 - so we get an image that is 500x500 and
 * also know that it is at 0.5x scale. The next page might be naturally 500x500 so we render it
 * at that size and know that it is at 1x scale, and these scales let us know that the second
 * page should be rendered at half the size of the first page.
 *
 * We base our scale on the actual size of the first page loaded.
 *
 * For some general reading about this type of widget and some tricks/css used here, see:
 * https://developers.google.com/web/updates/2016/07/infinite-scroller
 *
 * One wrinkle - jumbo frames. Normally we generate a div that is the actual height we need to fit
 * all our items and scroll around it normally. However, for long docs (or docs with large pages, or
 * when we're really zoomed in) that div can be very tall, and in particular it can be taller than
 * the max div height the browser supports! In this case we move to "jumbo mode" wherein the scroll
 * node height is capped and represents only a range of the actual doc pages. We pick a range so
 * that the target page's position with the range roughly matches the target page's position within
 * the whole doc, so that the scroll bar position is roughly right; we take advantage of the fact
 * that the scroll window is probably pretty large, so the user won't notice the fact the scroll bar
 * is moving too fast compared with the entire size of the doc and probably won't manually scroll far
 * enough to reach the end of the frame. When the user gets far enough from the original target page
 * (either jumping away to a new page, or scrolling fast) we pick a new frame.
 * There may be some quirky behavior lurking around the edges here but I hope those situations will
 * be rare.
 */
export class ContinuousScroll<I extends BaseScrollItem> {
    // How much padding do we want around the items?
    static readonly DEFAULT_PADDING = 10;
    // What's the largest we can make the scrolling div? Beyond this, we'll be in "jumbo mode".
    private static readonly MAX_DIV_HEIGHT = determineMaxDivHeight();
    // How many pages should we always try and include around our target page when determining a
    // jumbo frame? (see setScrollFrame)
    private static readonly MIN_JUMBO_WIDTH_PAGES = 1000;
    // How far away from the most recent render position should we let the user get before forcing a
    // jumbo reframe?
    private static readonly JUMBO_SCROLL_LIMIT = ContinuousScroll.MAX_DIV_HEIGHT / 100;
    // We "debounce" the actual fetching of items when the user scrolls (so that we don't end up
    // fetching every time along their entire scroll, only those items that we need after they stop
    // scrolling). This is the delay we use for that debounce.
    private static readonly SCROLL_DELAY = 150;
    // Page items up to this distance (in page number) away from the currently rendered ones will be cached.
    // Ones farther away will be discarded.
    static readonly CACHE_WIDTH = 10;
    // Used for rendering a Letter-sized tombstone as we load the very first item.
    static readonly DEFAULT_ASPECT_RATIO: number = 11 / 8.5;
    // Max width for DisplayMode.EXPAND_TO_WIDTH. Must match continuous-scroll--expand-to-width.
    static readonly EXPAND_TO_WIDTH_MAX: number = 800;
    // How much width do we want to reserve for the sidebar in fullscreen mode?
    private static readonly FULLSCREEN_WIDTH = 56;

    // Main scroller node.
    readonly node: HTMLElement;
    // Encapsulates the rendered scroller runway in the DOM.
    private readonly rendered: RenderedItems<I | TombstoneItem | ErrorItem>;
    // A cache of fetched items by page number. These may be evicted.
    private pages: { [pageNum: number]: I | ErrorItem } = {};
    // A store of rendered page sizes, used to calculate scroller height and tombstone size. We
    // update this whenever we load a new page or change the scale by a fixed amount.
    private pageSizes: { [pageNum: number]: Size } = {};
    // We can also have individual pages be rotated as opposed to just the whole document.
    private pageRotations: { [pageNum: number]: number } = {};
    // Our estimate for the dimensions of pages we haven't fetched yet.
    private sizeEstimate: Size;
    // A cache of tombstone elements we can reuse.
    private tombstoneCache: TombstoneItem[] = [];
    // The page number and offset (from item top) that we're currently at. This changes when the
    // user scrolls around, but we should keep it constant when we modify the DOM otherwise (e.g.
    // after an item is loaded and we want to put it in place) so that the user doesn't get bumped
    // around. This is not necessarily up-to-date - it is updated by a scroll callback.
    private anchor: Anchor = { pageNum: 0, offset: 0 };
    // Some document information we need:
    // The total number of pages.
    private numPages: number = 1;
    // Bookkeeping stuff.
    private initialized: boolean = false;
    protected destroyed = false;
    private scrollTimeout: number | null = null;
    private readonly gutterHeight: number;
    private preRoll: number = 0;
    private postRoll: number = 0;
    private rotation = Rotation.NONE;
    // Should we immediately trigger a load on the next scroll?
    private programmaticScroll = false;
    // Are we in jumbo mode, where our scroll div is too large to fit in a single browser div?
    private jumboMode = false;
    // Our current frame of pages that we are representing with our scroll div. Usually this is all
    // the pages; in jumbo mode, it's only a subrange.
    private scrollFrame: { firstPage: number; lastPage: number } = { firstPage: 0, lastPage: 0 };
    // The last scroll offset within our scrollFrame where we actually loaded images.
    // We use this to determine when to switch jumbo frames.
    private lastRenderPos = 0;
    private destroyables: Util.Destroyable[] = [];

    private readonly getPrePostRoll: () => [number, number];
    private readonly loadPages: (curPage: number, visible: number[], nonVisible: number[]) => void;
    private readonly onUpdate: (start: number, end: number) => void;
    private readonly onInsert: (item: I | ErrorItem | TombstoneItem) => void;
    private readonly currPageOverride: () => number | void;
    private readonly onScroll: (pageNum: number, clearSelectedHit?: boolean) => void;
    private readonly onInit: () => void;
    private readonly isFullScreenMode: boolean;
    private readonly closePopupOnTap: boolean;
    private readonly keepOutOfWindowPopup: boolean;
    private displayMode: DisplayMode;
    // This variable keeps track of the most recent location that was scrolled to via the "scrollTo"
    // method--this is used to keep track of whether has since manually scrolled to some other location
    // after calling that method
    private currentScrollTo: number;
    private readonly padding: number;

    /**
     * Create the scroller runway with a placeholder page. Once the first page is ready to display,
     * the caller should pass it to {@link init()}.
     */
    constructor(params: ContinuousScrollParams<I>) {
        this.getPrePostRoll = params.getPrePostRoll;
        this.loadPages = params.loadPages;
        this.onUpdate = params.onUpdate ? params.onUpdate : () => {};
        this.onInsert = params.onInsert ? params.onInsert : () => {};
        this.currPageOverride = params.currPageOverride ? params.currPageOverride : () => {};
        this.onScroll = params.onScroll ? params.onScroll : () => {};
        this.onInit = params.onInit ? params.onInit : () => {};
        const initZoom: ZoomInfo = params.initZoom ?? { dim: ZoomDim.WIDTH, scale: 1 };
        this.isFullScreenMode = !!params.isFullScreenMode;
        this.closePopupOnTap = !!params.closePopupOnTap;
        this.keepOutOfWindowPopup = !!params.keepOutOfWindowPopup;
        this.padding = Is.defined(params.padding)
            ? params.padding
            : ContinuousScroll.DEFAULT_PADDING;
        this.gutterHeight = params.gutterHeight - this.padding;
        const displayMode: DisplayMode = params.displayMode ?? DisplayMode.MAX_CONTENT;

        this.rendered = new RenderedItems<I | TombstoneItem | ErrorItem>();
        this.node = this.rendered.node;
        Dom.place(this.node, params.parent);

        this.setDisplayMode(displayMode);

        const initialViewportSize: Size = {
            width: this.availableWidth(),
            height: this.availableHeight(),
        };

        // Before the first page is available, load a tombstone that's zoomed to the provided
        // dimension and scale. When the scale is 1, the page will fill the entire width or height
        // of the viewer (according to the dimension).
        this.sizeEstimate = getInitialSizeEstimate(initialViewportSize, initZoom, displayMode);
        this.insertRenderedItem(0, "first");
    }

    /**
     * Available viewport height.
     *
     * Accounts for a possible bottom scrollbar.
     */
    availableHeight(): number {
        // If there isn't already a scrollbar, reserve room for one.
        const scrollBar = this.node.scrollWidth > this.node.clientWidth ? 0 : UI.SCROLLBAR_WIDTH;
        return Math.max(0, this.node.clientHeight - 2 * this.padding - scrollBar);
    }

    /**
     * Available viewport width.
     *
     * Accounts for the right-hand scrollbar and the possible fullscreen sidebar.
     */
    availableWidth(): number {
        const availablePageSize: number = Math.max(0, this.node.clientWidth - 2 * this.padding);
        if (this.isFullScreenMode) {
            if (this.displayMode === DisplayMode.MAX_CONTENT) {
                // The page is centered, so subtract double the width of the sidebar.
                return availablePageSize - 2 * ContinuousScroll.FULLSCREEN_WIDTH;
            } else {
                // The page is left-aligned against the sidebar.
                return availablePageSize - ContinuousScroll.FULLSCREEN_WIDTH;
            }
        } else {
            return availablePageSize;
        }
    }
    /**
     * When the first page has been loaded, call this method to start the viewer.
     */
    init(numPages: number, currentPage: I | ErrorItem): void {
        if (this.destroyed) {
            return;
        }

        this.numPages = numPages;

        // Make sure we've scrolled to the current page, and have rendered a single tombstone.
        if (currentPage.pageNum !== 0) {
            this.setTotalHeight();
            this.scrollTo(currentPage.pageNum);
            this.updateDisplay(false);
        }

        // Replace the current page's tombstone with the loaded item.
        this.insertItem(currentPage, true);

        // Call our init function before kicking off any additional renders.
        this.onInit();

        // Build tombstones and start loading the other rendered pages.
        this.updatePreAndPostRoll();
        this.updateDisplayFixedAnchor(true);

        // Start listening for scroll updates.
        this.destroyables.push(
            dojo_on(this.node, "scroll", () => {
                // One important note about this callback - it should never end up scrolling the node
                // (e.g. by setting this.node.scrollTop). Otherwise there's the potential for an
                // unintended loop where this callback gets continuously triggered (by itself).

                // Close the dijit popup if necessary.
                !this.keepOutOfWindowPopup && this.closeOutOfWindowPopup();

                if (this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }
                if (this.programmaticScroll) {
                    // After a programmatic scroll, we want to immediately trigger a load.
                    this.updateDisplay();
                    this.programmaticScroll = false;
                } else {
                    // Otherwise (e.g. for user scrolls) we want to debounce this call, placing
                    // tombstones (or already-loaded images) on every scroll, but only fetching new
                    // images after a delay.
                    this.updateDisplay(false);
                    this.scrollTimeout = setTimeout(() => {
                        this.scrollTimeout = null;
                        this.updateDisplay();
                    }, ContinuousScroll.SCROLL_DELAY);
                }
            }),
        );
        // this is not ideal, but the popup's blur() isn't called consistently otherwise
        if (this.closePopupOnTap) {
            this.destroyables.push(
                dojo_on(this.node, Input.tap, () => {
                    // Close the dijit popup if necessary.
                    this.closePopup();
                }),
            );
        }

        this.initialized = true;
    }
    /**
     * Close the current dijit popup if it for this viewer but around an item that is no longer in
     * the viewport.
     */
    private closeOutOfWindowPopup(): void {
        // Close any popup around an item that is outside of the window.
        const popup = dijit_popup.getTopPopup();
        if (popup && popup.parent && this.node.contains(popup.parent.domNode)) {
            const bbox = (<HTMLElement>popup.parent.domNode).getBoundingClientRect();
            const view = this.node.getBoundingClientRect();
            if (
                bbox.top < view.top
                || bbox.bottom > view.bottom
                || bbox.left < view.left
                || bbox.right > view.right
            ) {
                dijit_popup.close(popup.widget);
            }
        }
    }
    /**
     * Close the current dijit popup if it is for this viewer.
     */
    private closePopup(): void {
        const popup = dijit_popup.getTopPopup();
        if (popup && popup.parent && this.node.contains(popup.parent.domNode)) {
            dijit_popup.close(popup.widget);
        }
    }
    destroy(): void {
        this.destroyed = true;
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = null;
        }
        Dom.destroy(this.node);
        for (const k in this.pages) {
            this.pages[k].destroy();
        }
        this.pages = {};
        Util.destroy(this.destroyables);
        this.destroyables = [];
    }
    /**
     * Scroll to a page (0-indexed). If the viewer is already on the current page, no scrolling is
     * done and the relevant callbacks aren't called. Returns if the viewer initiated a scroll.
     * This is safe to call iff the runway height is up-to-date.
     */
    scrollTo(pageNum: number, offset?: number): boolean {
        if (pageNum !== this.getCurrPage() || Is.defined(offset)) {
            // Figure out our new scroll frame.
            this.setScrollFrame(pageNum);
            let topPos = 0;
            for (let i = this.scrollFrame.firstPage; i < pageNum; i++) {
                topPos += this.getItemHeight(i);
            }

            // Set our render position now so that we don't end up recalculating the scroll frame
            // when the scroll callback hits.
            const newScrollTop = topPos + (offset || 0);
            // We should only set this.programmaticScroll, if the this.node.scrollTop is changed.
            // Otherwise, it will not trigger dojo_on(this.node, "scroll") in this.init() above.
            this.programmaticScroll = this.node.scrollTop !== newScrollTop;
            this.lastRenderPos = newScrollTop;
            this.setScrollTop(newScrollTop);
            return true;
        }
        return false;
    }
    private setScrollTop(newScrollTop: number): void {
        // scrollTop can't be a decimal value.
        this.node.scrollTop = Math.round(newScrollTop);
        this.currentScrollTo = this.node.scrollTop;
    }
    hasScrolledAway(): boolean {
        return this.node.scrollTop !== this.currentScrollTo;
    }
    rotateCW(): void {
        this.rotate(1);
    }
    rotateCCW(): void {
        this.rotate(3);
    }
    rotatePageCW(page: number): void {
        if (!this.initialized || page < 0 || page >= this.numPages) {
            return;
        }
        if (page in this.pageRotations) {
            this.pageRotations[page] = (this.pageRotations[page] + 1) % 4;
        } else {
            this.pageRotations[page] = 1;
        }
        this.redraw();
        this.scrollTo(page);
    }
    rotatePages(rotations?: Record<number, number>, pageNum?: number): void {
        let currPage;
        if (this.initialized) {
            currPage = this.getCurrPage();
        }
        // If this statement is false, it means the viewerPageUpdate in Base.ts was caused by scrolling to a page,
        // not by a rotation. This means the rotation has not changed, so we shouldn't try to change it, and
        // especially not do the end actions in the Is.defined(currPage) portion of this function.
        if (!Is.defined(pageNum) || pageNum === currPage) {
            if (!Is.defined(rotations)) {
                this.rotation = Rotation.NONE;
                this.pageRotations = {};
            } else {
                // We may modify the input by deleting the global rotation, so have to make a copy.
                const rotationsCopy = Object.assign({}, rotations);
                if ((-1) in rotationsCopy) {
                    this.rotation = rotationsCopy[-1];
                    delete rotationsCopy[-1];
                } else {
                    this.rotation = Rotation.NONE;
                }
                this.pageRotations = rotationsCopy;
            }
            if (Is.defined(currPage)) {
                // If currPage is undefined, the viewer isn't loaded, so don't redraw. It will draw once loaded.
                this.redraw();
                this.scrollTo(currPage);
            }
        }
    }

    updatePreAndPostRoll(): void {
        const prePost = this.getPrePostRoll();
        this.preRoll = prePost[0];
        this.postRoll = prePost[1];
    }

    getSizeEstimate(): Size {
        return this.sizeEstimate;
    }

    /**
     * Computes a reasonable "current page" number for the current view based on how far down we
     * are on the current page. Note that this is not always the same as the current anchor.
     */
    getCurrPage(): number {
        const bottomPage = this.getBottomPage();
        const override = this.currPageOverride();
        if (Is.defined(override)) {
            return <number>override;
        }
        const anchorIdx: number = this.anchor.pageNum - (this.rendered.first()?.pageNum ?? 0);
        let currPage = this.anchor.pageNum;
        if (
            this.anchor.offset > 0
            && bottomPage > this.anchor.pageNum
            && anchorIdx + 1 < this.rendered.length()
        ) {
            // There's some additional page being displayed past the anchor page. If there's more of
            // the next page being shown than the anchor page, prefer that one.
            // Based on the anchor, we know how much of the first page is visible.
            const firstAnchorPageHeight: number = this.getItemHeight(anchorIdx);
            const firstDisplayHeight: number = firstAnchorPageHeight - this.anchor.offset;
            // The second page has as much of the rest as can fit, up to its max size.
            const secondAnchorPageHeight: number = this.getItemHeight(anchorIdx + 1);
            const secondDisplayHeight: number = Math.min(
                secondAnchorPageHeight,
                this.node.clientHeight - firstDisplayHeight,
            );
            if (secondDisplayHeight > firstDisplayHeight) {
                currPage += 1;
            }
        }
        return currPage;
    }

    forPages(callback: (p: I | ErrorItem | TombstoneItem) => void): void {
        for (const k in this.pages) {
            callback(this.pages[k]);
        }
    }
    getAnchorPage(): number {
        return this.anchor.pageNum;
    }
    getNumPages(): number {
        return this.numPages;
    }
    getPage(page: number): I | ErrorItem | undefined {
        return this.pages[page];
    }
    getPageOffsets(): number[] | null {
        if (!this.initialized) {
            return null;
        }
        const offsets = new Array(this.numPages + 1);
        offsets[0] = 0;
        for (let i = 0; i < this.numPages; i++) {
            offsets[i + 1] = offsets[i] + this.getItemHeight(i);
        }
        return offsets;
    }

    /**
     * In responsive display modes, page sizes can change.
     */
    resized(): void {
        const pagesCanResize: boolean =
            this.displayMode === DisplayMode.FIT_CONTENT
            || this.displayMode === DisplayMode.EXPAND_TO_WIDTH;
        if (pagesCanResize) {
            // Update the anchor since we are about to change page heights.
            this.updateAnchor();
            this.rendered.forEach((item: I | TombstoneItem | ErrorItem): void => {
                this.updatePageSize(item);
            });
            this.positionRenders(true);
            this.setTotalHeight();
        }
    }

    getPageRotation(pageNum: number): Rotation {
        return pageNum in this.pageRotations
            ? (this.rotation + this.pageRotations[pageNum]) % 4
            : this.rotation;
    }
    /**
     * Scroll the viewer as necessary so that it shows the given rectangle (in page coordinates).
     * For some reason I could not get `scrollIntoView()` to work consistently (maybe because of
     * quirks with SVG elements?). In any case it's nice to have manual control over how we scroll
     * so that we can have appropriate padding and also make sure we end up on the right page as
     * indicated by `this.getCurrPage()`.
     * The logic (at least for horizontal scroll) is based on:
     * https://drafts.csswg.org/cssom-view/#scroll-an-element-into-view
     * Returns a boolean indicating whether there was any vertical scrolling done.
     */
    scrollToRect(pageNum: number, scrollLocation: Geom.Rectangle): boolean {
        // First, we figure out where the desired page starts in our scroller.
        let pageStart = 0;
        for (let i = this.scrollFrame.firstPage; i < pageNum; i++) {
            pageStart += this.getItemHeight(i);
        }
        // Next, we get the rendered size of the target page (which we need if we're currently
        // rotated). Note that if we haven't loaded the target page, we'll be using our default
        // width/height - if the actual page has different dimensions, this will be wrong! But that
        // seems sufficiently rare to accept...
        const pageSize: Size = this.getRotatedPageSize(pageNum);

        // Convert the given scroll location bounding box to our rendered coordinates. First, expand
        // it a bit so that we don't put it right on an edge...
        // We add this.padding to adjust the bounding boxes for the padding we have on
        // each element.
        // We then want to expand the boxes a bit - we use this.padding, so we we subtract
        // it off from the x/y coordinates and increase the width and height by twice the padding.
        // The net result is no ajustment to the x/y coordinates and an increase in width and height.
        const scaled: Geom.Rectangle = {
            x: scrollLocation.x,
            y: scrollLocation.y,
            width: scrollLocation.width + 2 * this.padding,
            height: scrollLocation.height + 2 * this.padding,
        };
        let top: number;
        let bottom: number;
        let left: number;
        let right: number;
        const pageRotation = this.getPageRotation(pageNum);
        // and then determine the boundaries given our current rotation.
        switch (pageRotation) {
            case Rotation.NONE:
                top = scaled.y;
                bottom = top + scaled.height;
                left = scaled.x;
                right = left + scaled.width;
                break;
            case Rotation.CLOCKWISE_90:
                // noinspection JSSuspiciousNameCombination (suppress IntelliJ warning)
                top = scaled.x;
                bottom = top + scaled.width;
                left = pageSize.width - scaled.y - scaled.height;
                right = left + scaled.height;
                break;
            case Rotation.CLOCKWISE_180:
                top = pageSize.height - scaled.height - scaled.y;
                bottom = top + scaled.height;
                left = pageSize.width - scaled.x - scaled.width;
                right = left + scaled.width;
                break;
            case Rotation.CLOCKWISE_270:
                top = pageSize.height - scaled.x - scaled.width;
                bottom = top + scaled.width;
                // noinspection JSSuspiciousNameCombination (suppress IntelliJ warning)
                left = scaled.y;
                right = scaled.y + scaled.height;
                break;
            default:
                Util.assertNever(pageRotation);
        }
        top = Math.floor(top);
        bottom = Math.floor(bottom);
        right = Math.floor(right);
        left = Math.floor(left);
        // Now we figure out the boundaries of our current viewport.
        const currTop = this.node.scrollTop - pageStart;
        const currBottom = currTop + this.node.clientHeight;
        const currLeft = this.node.scrollLeft;
        const currRight = currLeft + this.node.clientWidth;
        let vertScroll: boolean;
        // Finally, we can determine if we need to scroll vertically.
        if ((top <= currTop && bottom >= currBottom) || (top >= currTop && bottom <= currBottom)) {
            // The target is bigger than the viewport (and overlapping it) or entirely within it -
            // do nothing.
            vertScroll = false;
        } else {
            // We want to make sure that the page we end up on (as determined by this.getCurrPage())
            // is pageNum - to do that, we scroll from the top down, only as far as necessary to
            // get the rectangle on the screen.
            this.scrollTo(pageNum, Math.max(0, bottom - this.node.clientHeight));
            vertScroll = true;
        }
        // Then, determine if we need to scroll horizontally.
        if (left <= currLeft && right >= currRight) {
            // Do nothing - the target is bigger than the viewport and overlaps it.
        } else if (
            (left <= currLeft && right - left < this.node.clientWidth)
            || (right >= currRight && right - left > this.node.clientWidth)
        ) {
            // Scroll to the left boundary when the target is smaller than the viewport and sticking
            // out to the left, or when it is bigger and sticking out to the right.
            this.node.scrollLeft = left;
        } else if (
            (left <= currLeft && right - left > this.node.clientWidth)
            || (right >= currRight && right - left < this.node.clientWidth)
        ) {
            // Scroll to the right boundary when the target is smaller than the viewport and sticking
            // out to the right, or when it is bigger and sticking out to the left.
            this.node.scrollLeft = right - this.node.clientWidth;
        }
        return vertScroll;
    }
    private rotate(shift: number): void {
        this.rotation = (this.rotation + shift) % 4;
        this.rendered.toggleLandscapeStyle(!this.inPortrait());
        this.redraw();
    }

    /**
     * Change scroller page layout. Callers must rerender pages afterwards.
     */
    setDisplayMode(displayMode: DisplayMode): void {
        this.displayMode = displayMode;
        Dom.toggleClass(
            this.node,
            "continuous-scroll--fixed-size",
            displayMode === DisplayMode.MAX_CONTENT,
        );
        Dom.toggleClass(
            this.node,
            "continuous-scroll--fit-content",
            displayMode === DisplayMode.FIT_CONTENT,
        );
        Dom.toggleClass(
            this.node,
            "continuous-scroll--expand-to-width",
            displayMode === DisplayMode.EXPAND_TO_WIDTH,
        );
    }

    /**
     * Clears all items and estimates, and reloads them. This should be called after changing page
     * contents in a way that can't be accounted for by scaling.
     */
    rerenderPages(tombstoneDims: Size = this.sizeEstimate): void {
        while (this.rendered.length() > 0) {
            this.rendered.removeLast();
        }
        this.pageSizes = {};
        this.pages = {};
        this.tombstoneCache = [];
        this.sizeEstimate = { width: tombstoneDims.width, height: tombstoneDims.height };

        this.redraw();
    }

    /**
     * Update rendered item sizes, scroller height, and rendered items. This should be called after
     * changing zoom or rotation.
     */
    redraw(): void {
        this.rendered.forEach((r: I | TombstoneItem | ErrorItem): void => {
            this.rescale(r);
        });
        this.setTotalHeight();
        // After repositioning everything we need to update the scroll position as well.
        this.updateDisplayFixedAnchor(true, true);
    }

    private inPortrait(pageNum?: number): boolean {
        const rotation: Rotation =
            pageNum !== undefined ? this.getPageRotation(pageNum) : this.rotation;
        return rotation === Rotation.NONE || rotation === Rotation.CLOCKWISE_180;
    }

    /**
     * Return the rotated page size for a rendered or un-rendered scroll item. This doesn't account
     * for padding.
     */
    getRotatedPageSize(pageNum: number): Size {
        const pageSize: Size = this.pageSizes[pageNum] ?? this.sizeEstimate;
        // noinspection JSSuspiciousNameCombination (suppress IntelliJ warning)
        return this.inPortrait(pageNum)
            ? { width: pageSize.width, height: pageSize.height }
            : { width: pageSize.height, height: pageSize.width };
    }

    /**
     * Return the total height for a rendered or un-rendered scroll item, accounting for zoom,
     * rotation, and padding.
     */
    private getItemHeight(pageNum: number): number {
        const rotatedPageSize: Size = this.getRotatedPageSize(pageNum);
        return rotatedPageSize.height + 2 * this.padding;
    }

    /**
     * Scale the given item based on our current zoom and rotation.
     */
    private rescale(item: I | TombstoneItem | ErrorItem): void {
        if (item instanceof FixedSizeScrollItem) {
            const rotation: number = this.getPageRotation(item.pageNum);
            item.rescale(rotation);
        }
    }

    /**
     * After a fixed-scale zoom, all values in {@link pageSizes} will be out of date. Update them so
     * our height estimate and placeholder pages will be sized correctly.
     */
    updatePageSizes(zoomFactor: number): void {
        const oldRotatedAnchorPageSize: Size = this.getRotatedPageSize(this.anchor.pageNum);
        this.anchor = {
            pageNum: this.anchor.pageNum,
            offset: this.getScaledAnchorOffset(oldRotatedAnchorPageSize.height, zoomFactor),
        };

        this.sizeEstimate = {
            height: this.sizeEstimate.height * zoomFactor,
            width: this.sizeEstimate.width * zoomFactor,
        };

        for (const pageNum in this.pageSizes) {
            const itemSize: Size = this.pageSizes[pageNum];
            this.pageSizes[pageNum] = {
                height: itemSize.height * zoomFactor,
                width: itemSize.width * zoomFactor,
            };
        }
    }

    /**
     * Update our page size record. This should be called after an item is inserted, replaced, or
     * resized.
     */
    private updatePageSize(item: I | ErrorItem | TombstoneItem): void {
        const oldRotatedPageHeight: number = this.getRotatedPageSize(item.pageNum).height;
        const newRotatedPageHeight: number = item.page.offsetHeight;
        const newRotatedPageWidth: number = item.page.offsetWidth;

        // noinspection JSSuspiciousNameCombination
        this.pageSizes[item.pageNum] = this.inPortrait(item.pageNum)
            ? { height: newRotatedPageHeight, width: newRotatedPageWidth }
            : { height: newRotatedPageWidth, width: newRotatedPageHeight };

        if (item.pageNum === this.anchor.pageNum) {
            const scale: number = newRotatedPageHeight / oldRotatedPageHeight;
            this.anchor = {
                pageNum: this.anchor.pageNum,
                offset: this.getScaledAnchorOffset(oldRotatedPageHeight, scale),
            };
        }
    }

    /**
     * Updates the anchor offset after the anchor's page is scaled, accounting for the static
     * padding around the page.
     */
    private getScaledAnchorOffset(oldRotatedPageHeight: number, scale: number): number {
        if (this.anchor.offset <= this.padding) {
            return this.anchor.offset;
        } else if (this.anchor.offset <= this.padding + oldRotatedPageHeight) {
            return (this.anchor.offset - this.padding) * scale + this.padding;
        } else {
            return this.anchor.offset - oldRotatedPageHeight + oldRotatedPageHeight * scale;
        }
    }

    private initializeItem(item: I | ErrorItem): void {
        this.rescale(item);
        // For image items, make the SVG layer.
        item.onInitDom();
    }
    private setScrollFrame(pageNum: number, defaultHeight = 0): number {
        if (this.jumboMode) {
            // We need to figure out the appropriate subrange of pages that our scroller will represent.
            // To get the scroll bar in the right position, we want the target page to be situated
            // at the same percentage in the frame as it is in the entire document.
            // Determine that fraction (calculated as a fraction of the page count, but whatever).
            const targetFrac = pageNum / this.numPages;
            const centerPage = pageNum;
            // Our overall frame height
            let frameHeight = Math.min(
                this.getItemHeight(centerPage),
                ContinuousScroll.MAX_DIV_HEIGHT,
            );
            // The pixel offset of our target page in the frame.
            let firstHeight = 0;
            // The frame upper and lower boundaries.
            let firstPage = centerPage;
            let lastPage = centerPage;
            let canMoveForward = lastPage < this.numPages - 1;
            let canMoveBack = firstPage > 0;
            let currFrac = 0;
            while (canMoveBack || canMoveForward) {
                // In each loop, we test extending the frame in either/both directions based on the available
                // space, our page boundaries, and our target fraction.
                // Do we want to include the next page?
                // We can do so if we haven't already stopped in that direction and if one of the
                // following is true:
                //  - this is the only direction we can move
                //  - our current fraction is too large (we need more trailing pages)
                //  - we don't have enough pages buffering around our target page
                if (
                    canMoveForward
                    && (!canMoveBack
                        || currFrac > targetFrac
                        || lastPage - centerPage < ContinuousScroll.MIN_JUMBO_WIDTH_PAGES)
                ) {
                    const nextHeight: number = this.getItemHeight(lastPage + 1);
                    if (nextHeight + frameHeight < ContinuousScroll.MAX_DIV_HEIGHT) {
                        // We have enough space in the range to add the next page.
                        lastPage += 1;
                        frameHeight += nextHeight;
                        currFrac = firstHeight / frameHeight;
                    } else {
                        // Otherwise, we can't ever move in that direction again.
                        canMoveForward = false;
                    }
                }
                // Likewise here for the opposite direction.
                if (
                    canMoveBack
                    && (!canMoveForward
                        || currFrac <= targetFrac
                        || centerPage - firstPage < ContinuousScroll.MIN_JUMBO_WIDTH_PAGES)
                ) {
                    const prevHeight: number = this.getItemHeight(firstPage - 1);
                    if (prevHeight + frameHeight < ContinuousScroll.MAX_DIV_HEIGHT) {
                        firstPage -= 1;
                        frameHeight += prevHeight;
                        firstHeight += prevHeight;
                        currFrac = firstHeight / frameHeight;
                    } else {
                        canMoveBack = false;
                    }
                }
                canMoveForward = canMoveForward && lastPage < this.numPages - 1;
                canMoveBack = canMoveBack && firstPage > 0;
            }
            this.scrollFrame = { firstPage, lastPage };
            return frameHeight;
        } else {
            // We can fit the entire document.
            this.scrollFrame = { firstPage: 0, lastPage: this.numPages - 1 };
            return defaultHeight;
        }
    }
    private setTotalHeight(height: number = this.getTotalHeight()): void {
        this.jumboMode = height > ContinuousScroll.MAX_DIV_HEIGHT;
        height = this.setScrollFrame(this.anchor.pageNum, height);
        this.rendered.setHeight(height + this.gutterHeight - 1);
    }

    /**
     * Figure out what our current anchor is (page number and offset). This should be called just
     * before recomputing page heights or inserting pages so that we don't bump the user around.
     * This is not safe to call after we have scrolled to an un-rendered page.
     */
    private updateAnchor(): void {
        // How far down are we scrolled?
        let scrollTop = this.node.scrollTop;
        for (let page = this.scrollFrame.firstPage; page <= this.scrollFrame.lastPage; page++) {
            const pageHeight: number = this.getItemHeight(page);
            if (scrollTop < pageHeight) {
                this.anchor = { pageNum: page, offset: scrollTop };
                return;
            } else {
                scrollTop -= pageHeight;
            }
        }
        // Somehow we got all the way to the bottom...
        const lastPage = this.scrollFrame.lastPage;
        this.anchor = {
            pageNum: lastPage,
            offset: Math.min(scrollTop, this.getItemHeight(lastPage)),
        };
    }
    /**
     * What page number is the last page rendered in the current viewport?
     */
    getBottomPage(): number {
        let lastPage = this.anchor.pageNum;
        // How much visible height is left? It's the current viewport height minus whatever is used
        // by the first page.
        let height: number =
            this.node.clientHeight - this.getItemHeight(lastPage) + this.anchor.offset;
        // Keep adding pages until we run out of height.
        while (lastPage < this.numPages - 1 && height > 0) {
            lastPage += 1;
            height -= this.getItemHeight(lastPage);
        }
        return lastPage;
    }

    /**
     * Updates the rendered items for the current scroll position, and optionally kicks off loading
     * content for current tombstones. This is the highest-level method to update the display, so
     * it's generally safe to call.
     */
    private updateDisplay(fetchNew = true): void {
        // If we're in jumbo mode and we've scrolled far enough away from the last place we stopped
        // and loaded images, recalculate our scroll frame now.
        if (
            this.jumboMode
            && Math.abs(this.node.scrollTop - this.lastRenderPos)
                > ContinuousScroll.JUMBO_SCROLL_LIMIT
        ) {
            const fraction = this.node.scrollTop / this.node.scrollHeight;
            const page = Math.floor(this.numPages * fraction);
            this.lastRenderPos = this.node.scrollTop;
            this.setScrollFrame(page);
        }
        this.updateAnchor();
        this.updateDisplayFixedAnchor(fetchNew);
    }

    /**
     * Inserts an item into the DOM - this may be a tombstone if we haven't fetched the page yet.
     */
    private insertRenderedItem(page: number, position: "first" | "last"): void {
        let item: I | ErrorItem | TombstoneItem;
        if (page in this.pages) {
            // It wasn't rendered, but we have the image - make sure it's scaled.
            item = this.pages[page];
            this.rescale(item);
        } else {
            // We need a placeholder. Create it.
            item = this.newTombstone(page);
        }

        if (position === "first") {
            this.rendered.addFirst(item);
        } else {
            this.rendered.addLast(item);
        }
        this.updatePageSize(item);

        this.onInsert(item);
    }

    /**
     * Given a page range, update the items rendered in the scroller runway.
     */
    private updateRenderedItemRange(startPageNum: number, endPageNum: number): void {
        // Remove any existing rendered items that come *before* our desired range.
        while (
            this.rendered.length()
            && (this.rendered.first() as I | TombstoneItem | ErrorItem).pageNum < startPageNum
        ) {
            this.cacheIfTombstone(this.rendered.removeFirst());
        }
        // Remove any existing rendered items that come *after* our desired range.
        while (
            this.rendered.length()
            && (this.rendered.last() as I | TombstoneItem | ErrorItem).pageNum > endPageNum
        ) {
            this.cacheIfTombstone(this.rendered.removeLast());
        }

        // If no rendered pages remain, insert the first page from the new range. Going forward, we
        // can assume there is at least one rendered item.
        if (this.rendered.length() === 0) {
            this.insertRenderedItem(startPageNum, "first");
        }
        // Add any new renders that come *before* our existing rendered items.
        while ((this.rendered.first() as I | TombstoneItem | ErrorItem).pageNum > startPageNum) {
            const page = (this.rendered.first() as I | TombstoneItem | ErrorItem).pageNum - 1;
            this.insertRenderedItem(page, "first");
        }
        // Add any new renders that come *after* our existing rendered items.
        while ((this.rendered.last() as I | TombstoneItem | ErrorItem).pageNum < endPageNum) {
            const page = (this.rendered.last() as I | TombstoneItem | ErrorItem).pageNum + 1;
            this.insertRenderedItem(page, "last");
        }
    }

    /**
     * Figure out what nodes should be in the DOM, place them, then readjust our scroll position to
     * preserve our anchor. If `fetchNew` is set, we then go and fetch any necessary items in a
     * smart order.
     *
     * This is safe to call if {@link anchor} and {@link scrollFrame} are up-to-date.
     */
    private updateDisplayFixedAnchor(fetchNew: boolean, forceScroll = false): void {
        const bottomPage = this.getBottomPage();

        // We want nodes in this range [start, end] to be placed in the DOM.
        const startPageNum: number = Math.max(0, this.anchor.pageNum - this.preRoll);
        const endPageNum: number = Math.min(this.numPages - 1, bottomPage + this.postRoll);
        const oldStartPageNum: number | undefined = this.rendered.first()?.pageNum;
        const oldEndPageNum: number | undefined = this.rendered.last()?.pageNum;

        this.updateRenderedItemRange(startPageNum, endPageNum);

        // New pages were added iff the current range is contained within the old range.
        const newPagesAdded: boolean =
            oldStartPageNum === undefined
            || oldEndPageNum === undefined
            || startPageNum < oldStartPageNum
            || endPageNum > oldEndPageNum;
        const firstPageChanged: boolean = startPageNum !== oldStartPageNum;
        if (
            // We need to update the rotation offset for any new pages.
            newPagesAdded
            // We need to update the overall runway offset if the first page has changed.
            || firstPageChanged
            // By default, don't update the scrollTop unless we need to.
            || forceScroll
        ) {
            this.positionRenders(forceScroll);
        }

        this.onUpdate(startPageNum, endPageNum);
        if (fetchNew) {
            this.lastRenderPos = this.node.scrollTop;
            // The ordering here is:
            // Fetch everything visible in low def, starting with the current page.
            // Fetch the current page in high def if needed
            // Fetch low-def versions of prerolled (non-visible but nearby) pages
            // Fetch high-def versions of visible pages (other than the current page)
            // Fetch high-def versions of prerolled pages
            // We want to actually fetch images we don't already have.
            const currPage = this.getCurrPage();
            const visible: number[] = [];
            const nonVisible: number[] = [];
            // Prioritize loading all the visible pages starting with currPage and then proceeding
            // in page order (e.g. if the anchor is page 1 and the current page is 2 and page 3 is
            // also visible, load in order [2, 1, 3])
            if (currPage !== this.anchor.pageNum) {
                visible.push(this.anchor.pageNum);
            }
            for (let i = currPage + 1; i <= bottomPage; i++) {
                visible.push(i);
            }
            // Next, load low-def pages outside of our current visible set but within our preroll/postroll.
            // We load these pages in a snaking order that leans towards pages after our current page -
            // 2 postroll pages for every preroll page.
            for (let i = 0; i < Math.max(this.preRoll, Math.ceil(this.postRoll / 2)); i++) {
                // Do two pages after and then one page before, until we've run out of pages.
                [
                    bottomPage + 2 * i + 1,
                    bottomPage + 2 * i + 2,
                    this.anchor.pageNum - (i + 1),
                ].forEach((p) => {
                    if (p >= startPageNum && p <= endPageNum) {
                        nonVisible.push(p);
                    }
                });
            }
            this.loadPages(currPage, visible, nonVisible);
            // Get rid of any cached images we don't want.
            this.purgeOld();
            this.onScroll(currPage, !this.programmaticScroll);
        }
    }
    /**
     * Any removed tombstone items are saved for reuse.
     */
    private cacheIfTombstone(i?: BaseScrollItem): void {
        if (i instanceof TombstoneItem) {
            this.tombstoneCache.push(i);
        }
    }
    /**
     * Get rid of any old pages we have cached but which we don't want to keep around any more.
     */
    private purgeOld(): void {
        const cacheSize = Object.keys(this.pages).length;
        for (const k in this.pages) {
            if (!this.shouldCache(Number(k), this.pages[k], cacheSize)) {
                this.pages[k].destroy();
                delete this.pages[k];
            }
        }
    }
    /**
     * Is the given page actually in the DOM right now (either as an image or a tombstone)?
     */
    isRendered(pageNum: number): boolean {
        if (!this.rendered.length()) {
            return false;
        }
        const firstItem = this.rendered.first() as I | TombstoneItem | ErrorItem;
        const lastItem = this.rendered.last() as I | TombstoneItem | ErrorItem;

        return pageNum >= firstItem.pageNum && pageNum <= lastItem.pageNum;
    }

    /**
     * Called by consumers to pass in an item requested by {@link loadPages()}.
     *
     * We only render a limited contiguous window of pages. If the page is currently rendered
     * (usually as a tombstone), insert the new item immediately. Otherwise, cache it in {@link
     * pages} so it can be inserted when it's in-range.
     */
    onLoad(item: I | ErrorItem): void {
        if (this.isRendered(item.pageNum)) {
            this.insertItem(item);
        } else if (this.shouldCache(item.pageNum, item, Object.keys(this.pages).length)) {
            this.initializeItem(item);
            this.pages[item.pageNum] = item;
        }
    }

    /**
     * When an item is ready, insert it into the DOM. This is only safe to call when an item
     * (usually a tombstone) already exists for the page in the DOM.
     */
    private insertItem(item: I | ErrorItem, updateSizeEstimate: boolean = false): void {
        const oldHeight: number = this.getItemHeight(item.pageNum);
        this.updateAnchor();

        this.initializeItem(item);
        const firstItem = this.rendered.first() as I | TombstoneItem | ErrorItem;
        const renderIndex: number = item.pageNum - firstItem.pageNum;
        const oldItem = this.rendered.replaceItem(renderIndex, item) as
            | I
            | TombstoneItem
            | ErrorItem;
        this.cacheIfTombstone(oldItem);
        this.pages[item.pageNum] = item;

        this.updatePageSize(item);
        if (updateSizeEstimate) {
            this.sizeEstimate = this.pageSizes[item.pageNum];
        }

        // If page heights or the size estimate changes, we need to update the runway height.
        const newHeight: number = this.getItemHeight(item.pageNum);
        if (newHeight !== oldHeight || updateSizeEstimate) {
            this.setTotalHeight();
        }

        this.positionRenders(true);
        this.onInsert(item);
    }
    private getTotalHeight(): number {
        let total = 0;
        for (let i = 0; i < this.numPages; i++) {
            total += this.getItemHeight(i);
        }
        return total;
    }
    private shouldCache(pageNum: number, item: I | ErrorItem, cacheSize: number): boolean {
        if (this.rendered.length() === 0) {
            return false;
        }
        const firstItem = this.rendered.first() as I | TombstoneItem | ErrorItem;
        const lastItem = this.rendered.last() as I | TombstoneItem | ErrorItem;

        const cacheWidth = item.cacheWidth();
        const renderStart: number = firstItem.pageNum;
        const renderStop: number = lastItem.pageNum;
        return (
            (renderStart <= pageNum && pageNum <= renderStop)
            || Math.min(Math.abs(pageNum - renderStart), Math.abs(pageNum - renderStop))
                < cacheWidth
            || cacheSize < 2 * cacheWidth
        );
    }
    /**
     * Calculate the render offset and runway height, and update the scroll position to maintain the
     * current {@link anchor}.
     */
    private positionRenders(updateScroll = false): void {
        if (!this.rendered.length()) {
            return;
        }
        const firstItem = this.rendered.first() as I | TombstoneItem | ErrorItem;

        // First we need to figure out how far down the page to start.
        let topPos = 0;
        for (let i = this.scrollFrame.firstPage; i < firstItem.pageNum; i++) {
            topPos += this.getItemHeight(i);
        }

        // Set the height at which the first page will appear in the runway.
        this.rendered.setOffset(Math.round(topPos));

        // If requested, update the scroll position to maintain the current anchor.
        if (!updateScroll) {
            return;
        }
        this.rendered.forEach((r: I | TombstoneItem | ErrorItem): void => {
            if (r.pageNum === this.anchor.pageNum) {
                this.setScrollTop(topPos + this.anchor.offset);
                return;
            }
            topPos += this.getItemHeight(r.pageNum);
        });
    }

    /**
     * Get a tombstone (scaled appropriately for the current zoom), preferably reusing a cached one.
     */
    private newTombstone(pageNum: number): TombstoneItem {
        const pageSize: Size = this.pageSizes[pageNum] ?? this.sizeEstimate;

        let tombstone: TombstoneItem | undefined = this.tombstoneCache.pop();
        if (tombstone) {
            tombstone.pageNum = pageNum;
            tombstone.height = pageSize.height;
            tombstone.width = pageSize.width;
        } else {
            tombstone = new TombstoneItem(pageNum, pageSize.height, pageSize.width, this.padding);
        }

        this.rescale(tombstone);
        return tombstone;
    }
}

/**
 * Return a Letter-shaped page size, scaled to the current viewport, with any zoom settings applied.
 */
function getInitialSizeEstimate(
    viewportSize: Size,
    zoom: ZoomInfo,
    displayMode: DisplayMode,
): Size {
    if (displayMode === DisplayMode.EXPAND_TO_WIDTH) {
        const initialWidth: number = Math.min(
            viewportSize.width,
            ContinuousScroll.EXPAND_TO_WIDTH_MAX,
        );
        return {
            width: initialWidth,
            height: initialWidth * ContinuousScroll.DEFAULT_ASPECT_RATIO,
        };
    }

    if (zoom.dim === ZoomDim.WIDTH) {
        const initialWidth: number = viewportSize.width * zoom.scale;
        return {
            width: initialWidth,
            height: initialWidth * ContinuousScroll.DEFAULT_ASPECT_RATIO,
        };
    } else {
        const initialHeight: number = viewportSize.height * zoom.scale;
        return {
            width: initialHeight / ContinuousScroll.DEFAULT_ASPECT_RATIO,
            height: initialHeight,
        };
    }
}
